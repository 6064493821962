<template>
  <nav
    class="right_bg close-right-sidebar-16rem z-40 md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-w-2/12 z-10"
    v-bind:style="{ right: toggle_right_Sidebar + 'rem' }"
  >
    <div class="">
      <div class="flex bg-emerald-500 justify-between px-2 py-0 mneminics_mt">
        <div class="righ_side_head_">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="4"
            viewBox="0 0 12 4"
            fill="none"
          >
            <path
              d="M11.3333 0H0.666626V1.33333H11.3333V0ZM0.666626 4H11.3333V2.66667H0.666626V4Z"
              fill="#777777"
            ></path>
          </svg>
          <p class="sidebar-heading">TVD SECTION</p>
        </div>
        <div>
          <svg
            v-on:click="closeNavigation"
            class="cursor-pointer ml-auto mt-0 w-2 left_close_btn"
            style="text-align: right"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
          >
            <path
              id="close_2_"
              data-name="close (2)"
              d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
              transform="translate(0 -0.136)"
              fill="#b4b5ba"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_one"
        class="flex justify-between pt-4 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/genaral_settings.png" alt="" />
            General Settings
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureOne"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureOne ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="">
          <div class="custom-radio-wrap mb-4 mt-3">
            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> Switch Axis </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                  @click="toggleMnemonics()"
                  :checked="isSwitched"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_two"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/note.png" alt="" />
            Appearance
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureTwo"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureTwo ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-5">
          <div class="form-group-checkbox"></div>

          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Label</p>
            <input
              type="color"
              id="titleColor"
              class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md"
              @input="xAxisLabelColorHandler($event)"
              :value="xAxisLabel"
            />
          </div>

          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Actual Color</p>
            <input
              type="color"
              id="titleColor"
              class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md"
              @input="actualColorHandler($event)"
              :value="actualColor"
            />
          </div>

          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Plan Color</p>
            <input
              type="color"
              id="titleColor"
              class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md"
              @input="planColorHandler($event)"
              :value="planColor"
            />
          </div>

          <!-- <div class="flex items-center justify-between mt-3">
              <p class="label_heading">Axis label</p>
              <input type="color" id="valueColor" class="
                  focus:ring-indigo-500
                  label_heading
                  h-9
                  border-0
                  input-bg
                  block
                  w-5/12
                  rounded-md
                " @input="yAxisLabelColorHandler($event)" :value="yAxisLabelColor" />
            </div> -->

          <div class="flex flex-col mt-3">
            <p class="label_heading">X-axis label size</p>
            <input
              type="number"
              min="0"
              max="16"
              id="valueColor"
              class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
              @input="xAxisLabelSizeHandler($event)"
              :value="xAxisLabelSize"
            />
          </div>

          <div class="flex flex-col mt-3">
            <p class="label_heading">Y-axis label size</p>
            <input
              type="number"
              min="0"
              max="16"
              id="valueColor"
              class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
              @input="yAxisLabelSizeHandler($event)"
              :value="yAxisLabelSize"
            />
          </div>

          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Grid Color</p>
            <input
              type="color"
              min="0"
              max="13"
              id="unitColor"
              class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md"
              @input="gridColorHandler($event)"
              :value="gridColor"
            />
          </div>

          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Background</p>
            <input
              type="color"
              min="0"
              max="13"
              id="unitColor"
              class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md"
              @input="gridBackgroundHandler($event)"
              :value="gridBackground"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_four"
        class="flex justify-between pt-4 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/range_.png" alt="" />
            Ranges
          </p>
          <!-- <p class="sidebar-label">Ranges</p> -->
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureFour"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureFour ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="">
          <div class="custom-radio-wrap mb-4 mt-3">
            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> X-Manual Scale </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                  @click="toggleAxisAutoScale($event, 'xAutoScale')"
                  :checked="xAutoScale"
                />
              </label>
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">X-Start Range</p>
              <input
                type="number"
                class="fields border-0 block font-color"
                @input="changeRangeHandler($event, 'xRangeStart')"
                :value="xRangeStart"
              />
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">X-End Range</p>
              <input
                type="number"
                class="fields border-0 block font-color"
                @input="changeRangeHandler($event, 'xRangeEnd')"
                :value="xRangeEnd"
              />
            </div>

            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin mt-3">
                <span class="label_heading"> Y-Manual Scale </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                  @click="toggleAxisAutoScale($event, 'yAutoScale')"
                  :checked="yAutoScale"
                />
              </label>
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">Y-Start Range</p>
              <input
                type="number"
                class="fields border-0 block font-color"
                @input="changeRangeHandler($event, 'yRangeStart')"
                :value="yRangeStart"
              />
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">Y-End Range</p>
              <input
                type="number"
                class="fields border-0 block font-color"
                @input="changeRangeHandler($event, 'yRangeEnd')"
                :value="yRangeEnd"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="isUnitExpand=!isUnitExpand"
        class="flex justify-between pt-4 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/range_.png" alt="" />
            Unit
          </p>
          <!-- <p class="sidebar-label">Ranges</p> -->
        </div>
        <div class="d-flex self-center">
          <p v-if="!isUnitExpand"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isUnitExpand ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="">
          <div class="custom-radio-wrap mb-4 mt-3">
            <UnitSelect 
              :display-id="displayId" 
              :widget-id="widgetId" 
              :mnemonic="'dmea'" 
              :isMetrics="isWellMetrics" 
              :standaredUnit="dmea_units?.pnrg_unit"
              :interChange="true"
              :selected-val="unit && unit != '' ? unit : dmea_units?.display_unit"
              @change_unit_handler="changeUnitHandler"
            />
          </div>
        </div>
      </div>
    </div>
    <UpDownButtons
      :isExpandFeatureSix="isExpandFeatureSix"
      :toggle_height_handler_six="toggle_height_handler_six"
      :id="id"
      :displayId="properties.displayId"
      :widgetId="properties.widgetId"
      :closePropertyFile="closeNavigation"
    />
  </nav>
</template>

<script>
import UpDownButtons from "../UpDownButtons/UpDownButtons";
import UnitSelect from "./properties/unitSelectDirectional.vue";
import helperServices from "../../helper-services";
export default {
  name: "Maindash",
  components: {
    UpDownButtons,
    UnitSelect
  },
  props: {
    // blinkAlert: Boolean,
    upDownHandler: Function,
    toggle_right_Sidebar: {
      type: String,
      default: "",
    },
    toggle_right_margin: {
      type: String,
      default: "",
    },
    id: Number,
    widgetId: String,
    displayId: String,
    properties: {},
    closeNavigation: Function,
  },
  data() {
    return {
      isExpandFeatureOne: true,
      isExpandFeatureTwo: false,
      isExpandFeatureThree: false,
      isExpandFeatureFour: false,
      isExpandFeatureFive: false,
      isExpandFeatureSix: false,
      isUnitExpand:false,
      tag1: "",
      tag2: "",
      // tags: [],
      idx: -1,
      timer: null,
      dmea_units: null,
    };
  },
  beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.idx = i;
      }
    }
  },
  async mounted() {
    this.tag1 = "tvd";
    this.tag2 = "dispew";
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.idx = i;
      }
    }
    try{
      const dtls = this.$store.state.disp.displays[this.displayId];
      let mapping = await helperServices.getIndexDetails(dtls.wellId);
      mapping = mapping.data.logs[dtls.logType];
      const tags = mapping.tags;
      const mnemonicIndex = tags.findIndex((t) => t == "dmea");
      const sourceMnemonic = mapping.source[mnemonicIndex];
      for(let type in mapping.mnemonics){
        if(mapping.mnemonics[type].hasOwnProperty(sourceMnemonic)){
          this.dmea_units = mapping.mnemonics[type][sourceMnemonic]
          break;
        }
      }
    }
    catch(err){
      console.error(err);
    }
    // if(!this.$store.state.rect.rects[this.idx]?.unit && this.dmea_units?.display_unit){
    //   this.$store.dispatch("rect/swabSurgeRealTimedata", {
    //     displayId: this.displayId,
    //     widgetId: this.widgetId,
    //     value: this.dmea_units?.display_unit,
    //     field: 'unit'
    //   });
    // }
  },
  computed: {
    isWellMetrics(){
      const dtls = this.$store.state.disp.displays[this.displayId];
      if (
        dtls != null &&
        dtls.wellId != null &&
        dtls.wellboreId != null &&
        dtls.wellboreState != null
      ) {
        return dtls.unit_conversion;
      }
      return false;
    },
    isSwitched(){
      let rects = this.$store.state.rect.rects;
      // let foundRect = rects.find(rect=> rect.widgetId == this.widgetId && rect.displayId == this.displayId)
      let foundRect = rects[this.idx]
      if(foundRect && Object.keys(foundRect).includes('isSwitched')){
        return foundRect.isSwitched
      }
      else{
        return false
      }
    },
    xAxisLabel(){
      let rects = this.$store.state.rect.rects;
      // let foundRect = rects.find(rect=> rect.widgetId == this.widgetId && rect.displayId == this.displayId)
      let foundRect = rects[this.idx]
      if(foundRect && Object.keys(foundRect).includes('xAxisLabel')){
        return foundRect.xAxisLabel
      }
      else{
        return getComputedStyle(document.documentElement).getPropertyValue("--textColor")
      }
    },
    actualColor(){
      let rects = this.$store.state.rect.rects;
      let foundRect = rects[this.idx]
      if(foundRect && Object.keys(foundRect).includes('actualColor')){
        return foundRect.actualColor
      }
      else{
        return '#ff0000'
      }
    },
    planColor(){
      let rects = this.$store.state.rect.rects;
      let foundRect = rects[this.idx]
      if(foundRect && Object.keys(foundRect).includes('planColor')){
        return foundRect.planColor
      }
      else{
        return '#00d15a'
      }
    },
    xAxisLabelSize(){
      let rects = this.$store.state.rect.rects;
      let foundRect = rects[this.idx]
      if(foundRect && Object.keys(foundRect).includes('xAxisLabelSize')){
        return foundRect.xAxisLabelSize
      }
      else{
        return ''
      }
    },
    yAxisLabelSize(){
      let rects = this.$store.state.rect.rects;
      let foundRect = rects[this.idx]
      if(foundRect && Object.keys(foundRect).includes('yAxisLabelSize')){
        return foundRect.yAxisLabelSize
      }
      else{
        return ''
      }
    },
    gridColor(){
      let rects = this.$store.state.rect.rects;
      let foundRect = rects[this.idx]
      if(foundRect && Object.keys(foundRect).includes('gridColor')){
        return foundRect.gridColor
      }
      else{
        return getComputedStyle(document.documentElement).getPropertyValue("--textColor")
      }
    },
    gridBackground(){
      let rects = this.$store.state.rect.rects;
      let foundRect = rects[this.idx]
      if(foundRect && Object.keys(foundRect).includes('gridBackground')){
        return foundRect.gridBackground
      }
      else{
        return getComputedStyle(document.documentElement).getPropertyValue("--navBar2Bg")
      }
    },
    xAutoScale() {
      return this.$store.state.rect.rects[this.idx].xAutoScale;
    },
    yAutoScale() {
      return this.$store.state.rect.rects[this.idx].yAutoScale;
    },
    xRangeStart() {
      return this.$store.state.rect.rects[this.idx].xRangeStart;
    },
    yRangeStart() {
      return this.$store.state.rect.rects[this.idx].yRangeStart;
    },
    xRangeEnd() {
      return this.$store.state.rect.rects[this.idx].xRangeEnd;
    },
    yRangeEnd() {
      return this.$store.state.rect.rects[this.idx].yRangeEnd;
    },
    tags() {
      // this.activeDisplay = this.$store.state.data.selectedDisplay;
      let display = this.$store.state.disp.selectedDisplay;
      //
      let displayobj = this.$store.state.disp.displays;
      //
      if (displayobj[display]) {
        return displayobj[display].tagsTrejectory;
      } else {
        return [];
      }
      // return displayobj.tags;
    },
    title() {
      return this.$store.state.rect.rects[this.idx].title || "";
    },
    unit() {
      return this.$store.state.rect.rects[this.idx].unit;
    },
    decimalValue() {
      return this.$store.state.rect.rects[this.idx].decimalValue;
    },
    autoFit() {
      return this.$store.state.rect.rects[this.idx].autoFit;
    },

    titleSize() {
      return this.$store.state.rect.rects[this.idx].titleSize;
    },
    titleColor() {
      return this.$store.state.rect.rects[this.idx].titleColor;
    },
    valueSize() {
      return this.$store.state.rect.rects[this.idx].valueSize;
    },
    unitSize() {
      return this.$store.state.rect.rects[this.idx].unitSize;
    },
    unitColor() {
      return this.$store.state.rect.rects[this.idx].unitColor;
    },
    valueDisplay() {
      return this.$store.state.rect.rects[this.idx].valueDisplay;
    },
    unitDisplay() {
      return this.$store.state.rect.rects[this.idx].unitDisplay;
    },
    borderDisplay() {
      return this.$store.state.rect.rects[this.idx].borderDisplay;
    },
    blinkAlert() {
      return this.$store.state.rect.rects[this.idx].blinkAlert;
    },
    alertColor() {
      return this.$store.state.rect.rects[this.idx].alertColor;
    },
    alertEndRange() {
      return this.$store.state.rect.rects[this.idx].alertEndRange;
    },
    alertStartRange() {
      return this.$store.state.rect.rects[this.idx].alertStartRange;
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    changeUnitHandler(fact){
      this.$store.dispatch("rect/changeUnitConversionFactor", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: fact.conversion_factor,
        unit: fact.to_unit,
      });
    },
    changeRangeHandler(e, key) {
      if (e.target.value != "" && e.target.value != "-") {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          let temp = {};
          temp[key] = e.target.value;
          this.$store.dispatch("rect/trajectoryRangeChange", {
            displayId: this.displayId,
            widgetId: this.widgetId,
            value: temp,
          });
        }, 450);
      }
    },
    toggleAxisAutoScale(e, key) {
      let temp = {};
      temp[key] = e.target.checked;
      this.$store.dispatch("rect/trajectoryToggleAutoScale", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: temp,
      });
    },
    toggleMnemonics(e) {
      this.$store.dispatch("rect/toggleMnemonics", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    async mnemonicRemoveHandler(e, index) {
      this.$store.dispatch("rect/plotlyMnemonicChange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e,
        index,
      });
    },
    actualColorHandler(e) {
      this.$store.dispatch("rect/actualColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    planColorHandler(e) {
      this.$store.dispatch("rect/planColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    xAxisLabelColorHandler(e) {
      this.$store.dispatch("rect/lineGraphXAxis", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    yAxisLabelColorHandler(e) {
      this.$store.dispatch("rect/lineGraphYAxis", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    xAxisLabelSizeHandler(e) {
      if (Number(e.target.value) >= 7 && 17 > Number(e.target.value)) {
        this.$toast.clear();
        this.$store.dispatch("rect/lineGraphXAxisLabelSize", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
      } else {
        this.$toast.clear();
        this.$toast.error(`Fonts should be from 7 to 16`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    yAxisLabelSizeHandler(e) {
      if (Number(e.target.value) >= 7 && 17 > Number(e.target.value)) {
        this.$toast.clear();
        this.$store.dispatch("rect/lineGraphYAxisLabelSize", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
      } else {
        this.$toast.clear();
        this.$toast.error(`Fonts should be from 7 to 16`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },

    gridColorHandler(e) {
      this.$store.dispatch("rect/lineGraphGridColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    gridBackgroundHandler(e) {
      this.$store.dispatch("rect/lineGraphGridBackground", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },

    toggleAutoHandler() {
      this.$store.dispatch("rect/toggleAutoFit", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },

    toggleBorderVisibiltyHandler() {
      this.$store.dispatch("rect/toggleBorderVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    toggleValueVisibiltyHandler() {
      this.$store.dispatch("rect/toggleValueVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },

    changeAlertColor(e) {
      this.$store.dispatch("rect/changeAlertColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },

    // async mnemonicChangeHandler(e) {
    //   this.$store.dispatch("rect/numericMemonicChange", {
    //     displayId: this.properties.displayId,
    //     widgetId: this.properties.widgetId,
    //     value: e.target.value,
    //   });
    // },

    async mnemonicChangeHandler(e, index) {
      this.$store.dispatch("rect/lineGraphMnemonicChange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
        index,
      });
    },
    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    toggle_height_handler_two() {
      if (this.isExpandFeatureTwo == true) {
        this.isExpandFeatureTwo = false;
      } else {
        this.isExpandFeatureTwo = true;
      }
    },
    toggle_height_handler_three() {
      if (this.isExpandFeatureThree == true) {
        this.isExpandFeatureThree = false;
      } else {
        this.isExpandFeatureThree = true;
      }
    },
    toggle_height_handler_four() {
      if (this.isExpandFeatureFour == true) {
        this.isExpandFeatureFour = false;
      } else {
        this.isExpandFeatureFour = true;
      }
    },
    toggle_height_handler_five() {
      if (this.isExpandFeatureFive == true) {
        this.isExpandFeatureFive = false;
      } else {
        this.isExpandFeatureFive = true;
      }
    },
    toggle_height_handler_six() {
      if (this.isExpandFeatureSix == true) {
        this.isExpandFeatureSix = false;
      } else {
        this.isExpandFeatureSix = true;
      }
    },
    async getTags() {
      // let tagsResponse = await api.WellServices.getColumnsOfDepthLog();
      // this.tags = tagsResponse.data;
    },
    dark() {
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      this.darkMode = false;
      this.$emit("light");
    },
  },
  watch: {
    widgetId(newVal) {
      let rects = this.$store.state.rect.rects;
      if (typeof rects != "undefined" && rects.length > 0) {
        for (let i = 0; i < rects.length; i++) {
          if (
            rects[i].widgetId == this.widgetId &&
            rects[i].displayId == this.displayId
          )
            this.idx = i;
        }
      }
    },
  },
};
</script>

<style scoped src="../../assets/styles/propstyle.css">
/* .right_bg {
  background: #1b1a1f;
}
.bg_gray {
  width: 12%;
}
.bg-right_ {
  background: #28282f;
  margin: 5px 5px;
  padding: 5px;
  border-radius: 5px;
}
.sidebar-heading {
  font-size: 9px;
  padding: 2px;
}
.sidebar-label {
  font-size: 10px;
  font-weight: 400;
  display: flex;
  height: 100%;
  align-items: flex-end;

}
.label_heading {
 
  letter-spacing: 0.5px;
}
.custom-radio-wrap form .form-group-radio .label-text {
  color: white;
} */
</style>
